<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="师傅报完工" />
    <div class="search">
      <van-radio-group style="font-size:14px" icon-size="16px" v-model="dateType" direction="horizontal">
        <van-radio name="1">七天</van-radio>
        <van-radio name="2">近一月</van-radio>
        <van-radio name="3">自定义</van-radio>
        <van-radio name="4">全年</van-radio>
        <van-button type="primary" size="mini" @click="searchFn">查询</van-button>
      </van-radio-group>
    </div>
    <van-cell-group class="date-cell-group" v-show="dateType === '3'">
      <van-cell title="选择日期区间" :value="date" @click="show = true" is-link />
      <van-calendar v-model="show" type="range" :min-date="minDate" @confirm="onConfirm" />
    </van-cell-group>
    <div>
      <van-row>
        <van-col span="24">
          <div style="width: auto;height: 400px;" class="payMoney" id="ins" @click="installFn"></div>
        </van-col>
        <van-col span="24">
          <div style="width: auto;height: 400px;" class="payMoney" id="repair" @click="repaireFn"></div>
        </van-col>
      </van-row>
    </div>
    <van-overlay :show="isshow">
      <div class="wrapper">
        <van-loading color="#0094ff" size="24px" vertical>加载中...</van-loading>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      dateType: "1",
      date: "",
      show: false,
      minDate: new Date(2020, 1, 1),
      endDate: "",
      startDate: "",
      isshow: false,
    };
  },
  created() {
    this.searchFn();
  },
  methods: {
    aaa() {
      console.log(1111);
    },
    //维修工单
    repaireFn(e) {
      if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
        this.$router.push({
          path: "/masterFinished/repaire",
          query: {
            start: this.startDate,
            end: this.endDate,
          },
        });
      }
    },

    //安装工单
    installFn(e) {
      if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
        this.$router.push({
          path: "/masterFinished/install",
          query: {
            start: this.startDate,
            end: this.endDate,
          },
        });
      }
    },
    //折线图
    echartsFn(id, value) {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById(id));
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById(id));
      }
      myChart.setOption(
        {
          title: {
            text: value.title,
          },
          tooltip: {
            trigger: "axis",
            triggerOn: "click",
            enterable: true,
            // formatter: function (params) {
            //   console.log(params, this);
            //   // if (params.seriesType === `line`) {
            //   //   setTimeout(() => {
            //   //     this.hookToolTip = params.name;
            //   //   }, 1000);
            //   // }
            //   return "{b0}";
            // }.bind(this),
          },
          legend: {
            data: value.rightTitle,
            top: "6%",
          },
          grid: {
            top: "20%",
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          // },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: value.ddate,
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              interval: "auto",
              rotate: "45",
            },
            axisTick: {
              inside: true,
            },
          },
          yAxis: {
            type: "value",
          },
          series: value.ser,
        },
        true
      );
      myChart.on("click", function (params) {
        console.log(params);
      });
    },
    //折线图数据请求
    async installEcharts(params, value) {
      let res = await this.postRequest("/wechat-analysis/summarys", params);
      let date = [];
      let gdl = [];
      let sbs = [];
      res.data.forEach((v) => {
        date.push(v.ddate);
        gdl.push(v.orderct);
        sbs.push(v.machinect);
      });
      if (date.length > 20) {
        let a = date.map((v, i) => {
          if ((i + 1) % 7 === 1) {
            return v;
          } else {
            return "";
          }
        });
        date = a;
      }
      if (value === "install") {
        let obj = {
          title: "安装工单",
          rightTitle: ["安装工单量", "安装设备数"],
          ddate: date,
          ser: [
            {
              name: "安装工单量",
              type: "line",
              data: gdl,
            },
            {
              name: "安装设备数",
              type: "line",
              data: sbs,
            },
          ],
        };
        this.echartsFn("ins", obj);
      } else {
        let obj = {
          title: "维修工单",
          rightTitle: ["维修工单量", "维修设备数"],
          ddate: date,
          ser: [
            {
              name: "维修工单量",
              type: "line",
              data: gdl,
            },
            {
              name: "维修设备数",
              type: "line",
              data: sbs,
            },
          ],
        };
        this.echartsFn("repair", obj);
        this.isshow = false;
      }
    },
    //搜索
    searchFn() {
      this.isshow = true;
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
      };
      let obj = {};
      if (this.dateType === "4") {
        let nowDate = new Date();
        let date = {
          // 获取当前年份
          year: nowDate.getFullYear(),
          //获取当前月份
          month: nowDate.getMonth() + 1,
          //获取当天
          day: nowDate.getDate(),
        };
        let start = date.year + "/01/01";
        let end =
          date.year +
          "/" +
          (date.month > 9 ? date.month : "0" + date.month) +
          "/" +
          (date.day > 9 ? date.day : "0" + date.day);
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: "month",
          sumType: 0,
        };
      } else if (this.dateType === "3") {
        let start = this.startDate;
        let end = this.endDate;
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: "day",
          sumType: 0,
        };
      } else if (this.dateType === "2") {
        let start = this.dateUtils.getBeforeDate(31);
        // let end = this.dateUtils.formatDate(new Date());
        let end = this.dateUtils.getBeforeDate(1);
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: "day",
          sumType: 0,
        };
      } else {
        let start = this.dateUtils.getBeforeDate(7);

        // let end = this.dateUtils.formatDate(new Date());
        let end = this.dateUtils.getBeforeDate(1);
        obj = {
          startDate: start,
          endDate: end,
          timeConditionType: "day",
          sumType: 0,
        };
      }
      this.startDate = obj.startDate;
      this.endDate = obj.endDate;

      this.installEcharts(
        { sumMode: "install.comp", ...params, ...obj },
        "install"
      );
      this.installEcharts(
        { sumMode: "repair.comp", ...params, ...obj },
        "repair"
      );
    },
    async searchFn_Old() {
      if (this.dateType === "4") {
        let params = {
          openid: getUrlKey("openid", window.location.href),
          moduleId: "11",
          type: "家用",
          startYearMonth: "2022-01",
          endYearMonth: "2022-10",
        };
        let date_x = [];
        let azgdl = [];
        let wxgdl = [];
        let res = await this.postRequest("/wechat-analysis/fees-month", params);
        res.data.forEach((v) => {
          date_x.push(v.dmonth);
          azgdl.push(v.insorder);
          wxgdl.push(v.reporder);
        });

        let obj = {
          title: "安装工单",
          rightTitle: ["安装工单量", "去年工单量", "安装设备数", "去年设备数"],
          ddate: date_x,
          ser: [
            {
              name: "安装工单量",
              type: "line",
              data: azgdl,
            },
          ],
        };
        let obj2 = {
          title: "维修工单",
          rightTitle: ["维修工单量"],
          ddate: date_x,
          ser: [
            {
              name: "维修工单量",
              type: "line",
              data: wxgdl,
            },
          ],
        };
        this.echartsFn("ins", obj);
        this.echartsFn("repair", obj2);
      } else {
        let xx = 0;
        let start = null;
        let end = null;
        if (this.dateType === "1") {
          xx = 7;
          start = this.dateUtils.getBeforeDate(xx);
          end = this.dateUtils.formatDate(new Date());
        } else if (this.dateType === "2") {
          xx = 30;
          start = this.dateUtils.getBeforeDate(xx);
          end = this.dateUtils.formatDate(new Date());
        } else {
          start = this.startDate;
          end = this.endDate;
        }

        let params = {
          openid: getUrlKey("openid", window.location.href),
          moduleId: "11",
          type: "家用",
          startDate: start,
          endDate: end,
          sumMode: "install.comp",
          timeConditionType: "day",
          sumType: 0,
        };
        this.startDate = params.startDate;
        this.endDate = params.endDate;

        this.postRequest("/wechat-analysis/summarys", params).then((res) => {
          console.log(res);
          // let date_x = [];
          // let bwgazgdl = [];
          // let qnbwgazgdl = [];
          // let azsbs = [];
          // let qnsbs = [];
          // let wxgdl = [];
          // let qnwxgdl = [];
          // let wxsbs = [];
          // let qnwxsbs = [];
          // for (let i = 0; i < res.data.length; i++) {
          //   if (res.data[i].btype === "维修") {
          //     date_x.push(res.data[i].ddate);
          //     wxgdl.push(res.data[i].ctorder);
          //     qnwxgdl.push(res.data[i].nct);
          //     wxsbs.push(res.data[i].machinect);
          //     qnwxsbs.push(res.data[i].nmachine);
          //   }
          //   if (res.data[i].btype === "材料") {
          //     bwgazgdl.push(res.data[i].ctorder);
          //     qnbwgazgdl.push(res.data[i].nct);
          //     azsbs.push(res.data[i].machinect);
          //     qnsbs.push(res.data[i].nmachine);
          //   }
          // }
          // if (date_x.length > 20) {
          //   let a = date_x.map((v, i) => {
          //     if ((i + 1) % 7 === 1) {
          //       return v;
          //     } else {
          //       return "";
          //     }
          //   });
          //   date_x = a;
          // }
          // let obj = {
          //   title: "安装工单",
          //   rightTitle: [
          //     "安装工单量",
          //     "去年工单量",
          //     "安装设备数",
          //     "去年设备数",
          //   ],
          //   ddate: date_x,
          //   ser: [
          //     {
          //       name: "安装工单量",
          //       type: "line",
          //       data: bwgazgdl,
          //     },
          //     {
          //       name: "去年工单量",
          //       type: "line",
          //       data: qnbwgazgdl,
          //     },
          //     {
          //       name: "安装设备数",
          //       type: "line",
          //       data: azsbs,
          //     },
          //     {
          //       name: "去年设备数",
          //       type: "line",
          //       data: qnsbs,
          //     },
          //   ],
          // };
          // let obj2 = {
          //   title: "维修工单",
          //   rightTitle: [
          //     "维修工单量",
          //     "去年工单量",
          //     "维修设备数",
          //     "去年设备数",
          //   ],
          //   ddate: date_x,
          //   ser: [
          //     {
          //       name: "维修工单量",
          //       type: "line",
          //       data: wxgdl,
          //     },
          //     {
          //       name: "去年工单量",
          //       type: "line",
          //       data: qnwxgdl,
          //     },
          //     {
          //       name: "维修设备数",
          //       type: "line",
          //       data: wxsbs,
          //     },
          //     {
          //       name: "去年设备数",
          //       type: "line",
          //       data: qnwxsbs,
          //     },
          //   ],
          // };
          // this.echartsFn("ins", obj);
          // this.echartsFn("repair", obj2);
        });
      }
    },
    //日期区间
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)
      }/${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.startDate = this.formatDate(start);
      this.endDate = this.formatDate(end);
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.search {
  background-color: #fff;
  padding: 10px 0;
  margin-top: 10px;
  .van-radio-group {
    justify-content: space-around;
    align-items: center;
  }
}
.payMoney {
  background-color: #fff;
  margin-top: 10px;
  padding-top: 10px;
}
</style>